import cn from 'classnames';
import React from 'react';
import { formatPrice } from 'utils/format-price';

interface PotentialSalesBarProps {
  totalSales: number;
  potentialSales: number;
}

export const PotentialSalesBar: React.FC<PotentialSalesBarProps> = ({ totalSales, potentialSales }) => {
  // Calculate the percentage of totalSales compared to potentialSales
  const totalSalesPercentage = (totalSales / potentialSales) * 100;

  return (
    <div className='w-full relative'>
      {/* Potential Sales Bar */}
      <div
        className={cn(
          'w-full bg-[repeating-linear-gradient(45deg,_#e5e7eb_0px,_#e5e7eb_10px,_#f3f4f6_10px,_#f3f4f6_20px)] relative rounded-md',
          'flex justify-end items-center h-[70px]'
        )}>
        <span className={cn('text-default-500 font-semibold text-end max-w-[80px] text-[16px] mr-md')}>
          Potential Sales
        </span>
      </div>

      {/* Total Sales Bar */}
      <div
        className={cn(
          'absolute top-0 left-0 bg-gradient-to-r from-green-500 to-green-400 z-2 rounded-md',
          'flex justify-end items-center h-[70px]'
        )}
        style={{ width: `${totalSalesPercentage}%` }}>
        <span className={cn('text-white font-semibold text-end max-w-[80px] text-[16px] mr-md')}>Total Sales</span>
      </div>

      <div className='flex mt-1'>
        <p className='text-sm font-semibold' style={{ marginLeft: `${totalSalesPercentage / 2}%` }}>
          {formatPrice(totalSales, 'AED')}
        </p>
        <p className='ms-auto text-sm font-semibold hidden'>{formatPrice(potentialSales, 'AED')}</p>
      </div>
    </div>
  );
};

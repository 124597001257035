import { Image, Typography } from 'antd';
import cn from 'classnames';
import { useEffect, useMemo } from 'react';
import { IoAnalyticsSharp } from 'react-icons/io5';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import { BrandLogo } from './components/brand-logo';
import { getSupplierDetails } from './server/get-supplier-details';
import { consoleLogs } from './utils/console-logs';

const Header = () => {
  const { supplierId } = useParams<{ supplierId: string; restaurantId: string }>();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const supplierDetails = useMemo(() => {
    return getSupplierDetails(Number(supplierId));
  }, [supplierId]);

  const onLogoClick = () => {
    navigate({ pathname: `/crm/${supplierId}` }, { replace: true });
  };

  const onSearchRestaurantClick = () => {
    navigate({ pathname: `/crm/${supplierId}/restaurant` }, { replace: true });
  };

  return (
    <div className='container mx-auto'>
      <div className='flex items-center justify-between h-[80px]'>
        <BrandLogo onClick={onLogoClick} className='cursor-pointer' />

        <div className='flex items-center gap-3xl'>
          <div
            onClick={onSearchRestaurantClick}
            className={cn(
              'rounded-full py-sm px-lg font-semibold cursor-pointer text-[16px]',
              'bg-gradient-to-r from-sky-600 to-primary-400 hover:from-sky-700 hover:to-primary-500 hover:drop-shadow-xl',
              'flex items-center gap-2xs text-white',
              { hidden: !!pathname.includes('restaurant') }
            )}>
            <IoAnalyticsSharp size={23} />
            <p>Analyze Restaurants</p>
          </div>

          <div className='flex items-center gap-xs'>
            <Image
              width={35}
              height={35}
              src={supplierDetails?.image}
              preview={false}
              fallback='https://placehold.co/50?text=img&font=roboto'
            />

            <Typography.Text
              style={{ maxWidth: 200 }}
              ellipsis={{
                suffix: '',
                tooltip: supplierDetails?.name,
              }}
              className='font-semibold text-lg !mb-0'>
              {supplierDetails?.name}
            </Typography.Text>
          </div>
        </div>
      </div>
    </div>
  );
};

const CRMPage = () => {
  useEffect(() => {
    consoleLogs();
  }, []);

  return (
    <div>
      <Header />
      <Outlet />
    </div>
  );
};

export default CRMPage;

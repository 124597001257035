import { useGetUnitsApi } from 'apis/use-get-units-api';
import { useMeApi } from 'apis/use-me-api';
import { FullPageSpinner } from 'components/full-page-spinner';
import { PleaseLoginModal } from 'components/please-login-modal';
import { PreLoginRoute } from 'components/pre-login-route';
import { ProtectedRoute } from 'components/protected-route';
import { updateAbility } from 'configs/ability';
import { useAppCheck } from 'hooks/use-app-check';
import { useAuth } from 'hooks/use-auth';
import { useGuestCheck } from 'hooks/use-guest-check';
import { useInitiateFirebaseUser } from 'hooks/use-initiate-firebase-user';
import { useSetAccessTokenFromSearchParams } from 'hooks/use-set-access-token-from-search-params';
import { useSetLangHtmlTags } from 'hooks/use-set-lang-html-tags';
import { useSyncLogout } from 'hooks/use-sync-logout';
import AccessDeniedPage from 'pages/access-denied';
import CRMPage from 'pages/crm';
import { RestaurantDetailsScreen } from 'pages/crm/screens/restaurant-details-screen';
import { SupplierDetailsScreen } from 'pages/crm/screens/supplier-details-screen';
import GetStartedPage from 'pages/get-started';
import LoginPage from 'pages/login';
import MarketingPage from 'pages/marketing';
import { RecommendationsScreen } from 'pages/marketing/screens/recommendation-screen';
import NotFoundPage from 'pages/not-found';
import OrdersPage from 'pages/orders';
import { OrderDetailsScreen } from 'pages/orders/screens/order-details-screen';
import { OrdersListScreen } from 'pages/orders/screens/orders-list-screen';
import { TableConfirmedOrders } from 'pages/orders/screens/orders-list-screen/table-confirmed-orders';
import { TableNewOrders } from 'pages/orders/screens/orders-list-screen/table-new-orders';
import { TableOrderHistory } from 'pages/orders/screens/orders-list-screen/table-order-history';
import ProductsPage from 'pages/products';
import { ProductsListScreen } from 'pages/products/screens/products-list-screen';
import ProfilePage from 'pages/profile';
import RequestReceivedPage from 'pages/request-received';
import VerifyCode from 'pages/verify-code';
import { useEffect } from 'react';
import { Navigate, Outlet, Route, Routes } from 'react-router-dom';
import { URL } from 'utils/constants';
import { SupplierCrmPage, SupplierCrmViewPage } from './pages/supplier-crm-product/screens';
import { SupplierCrmRestaurantScreen } from './pages/supplier-crm-product/screens/supplier-crm-restaurant-list-screen';
import { SupplierCrmRestaurantViewScreen } from './pages/supplier-crm-product/screens/supplier-crm-restaurant-view-screen';

const PostLoginApp = () => {
  return <Outlet />;
};

const HomePage = () => {
  const { isLoggedIn } = useAuth();
  if (isLoggedIn) return <Navigate to={URL.ORDERS} />;
  return <Navigate to={URL.GET_STARTED} />;
};

function App() {
  const { isLoading: isLoadingSetAccessToken } = useSetAccessTokenFromSearchParams();
  const { isLoading: isLoadingGuestCheck } = useGuestCheck();
  const { isLoading: isLoadingAppCheck } = useAppCheck();
  const { isLoading: isLoadingMeApi, data } = useMeApi();
  const { data: me } = data ?? {};
  useGetUnitsApi();

  useSetLangHtmlTags();
  useInitiateFirebaseUser();
  useSyncLogout();

  // Update user abilities
  useEffect(() => {
    const abilities = me?.roles?.[0]?.abilities ?? [];
    updateAbility(abilities);
  }, [me]);

  if (isLoadingSetAccessToken || isLoadingAppCheck || isLoadingMeApi || isLoadingGuestCheck) {
    return <FullPageSpinner />;
  }

  // Conditionally render development routes
  const renderDevelopmentRoutes = () => {
    if (process.env.REACT_APP_ENV_VAR !== 'production') {
      return (
        <>
          <Route path='supplier-crm-restaurant-lists' element={<SupplierCrmPage />}>
            <Route index element={<SupplierCrmRestaurantScreen />} />
          </Route>

          <Route path='supplier-crm-restaurant-view' element={<SupplierCrmViewPage />}>
            <Route index element={<SupplierCrmRestaurantViewScreen />} />
          </Route>
        </>
      );
    }
    return null;
  };

  return (
    <>
      <Routes>
        <Route path='/'>
          {/* DEFAULT ROUTE */}
          <Route index element={<HomePage />} />

          {/* PRE LOGIN */}
          <Route path='get-started' element={<PreLoginRoute component={<GetStartedPage />} />} />
          <Route path='request-received' element={<PreLoginRoute component={<RequestReceivedPage />} />} />
          <Route path='login' element={<PreLoginRoute component={<LoginPage />} />} />
          <Route path='verify-code' element={<PreLoginRoute component={<VerifyCode />} />} />

          {/* POST LOGIN */}
          <Route path='app' element={<ProtectedRoute component={<PostLoginApp />} />}>
            <Route path='orders' element={<OrdersPage />}>
              <Route element={<OrdersListScreen />}>
                <Route index element={<TableNewOrders />} />
                <Route path='confirmed' element={<TableConfirmedOrders />} />
                <Route path='history' element={<TableOrderHistory />} />
              </Route>

              <Route path='details/:orderId' element={<OrderDetailsScreen />} />
            </Route>

            <Route path='products' element={<ProductsPage />}>
              <Route index element={<ProductsListScreen />} />
            </Route>

            <Route path='profile' element={<ProfilePage />} />

            <Route path='marketing' element={<MarketingPage />}>
              <Route index element={<RecommendationsScreen />} />
            </Route>

            {/* Conditionally render development routes */}
            {renderDevelopmentRoutes()}

            {/*Add New Plop Route*/}
          </Route>

          {/* CRM */}
          <Route path='crm/:supplierId' element={<CRMPage />}>
            <Route index element={<SupplierDetailsScreen />} />
            <Route path='restaurant/:restaurantId?' element={<RestaurantDetailsScreen />} />
          </Route>

          {/* NOT FOUND */}
          <Route path='access-denied' element={<AccessDeniedPage />} />
          <Route path='404' element={<NotFoundPage />} />
          <Route path='*' element={<Navigate to={URL.NOT_FOUND} />} />
        </Route>
      </Routes>

      {/* General Components */}
      <PleaseLoginModal />
    </>
  );
}

export default App;

import { Badge, Image, ImageProps } from 'antd';

interface RestaurantImageProps extends ImageProps {
  isNew?: boolean;
}

export const RestaurantImage = ({ isNew, ...rest }: RestaurantImageProps) => {
  return (
    <>
      {!!isNew && (
        <Badge.Ribbon text='New' placement='start' color='geekblue'>
          <Image preview={false} width={70} fallback='https://placehold.co/400?text=img&font=roboto' {...rest} />
        </Badge.Ribbon>
      )}

      {!isNew && (
        <Image preview={false} width={70} fallback='https://placehold.co/400?text=img&font=roboto' {...rest} />
      )}
    </>
  );
};

import type { TableColumnsType, TableProps } from 'antd';
import { Empty, Image, Table } from 'antd';
import { TextCell } from 'components/text-cell';
import { Layout, Section } from 'layout';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useGetRestaurantDataItemsTheyDoNotOrderFromYouApi } from '../../../../apis/crm/use-get-restaurant-data-items-they-dont-order-from-you-api';
import { useGetRestaurantDataItemsTheyOrderFromYouApi } from '../../../../apis/crm/use-get-restaurant-data-items-they-order-from-you-api';
import { RestaurantCrmData } from '../../types/restaurant-crm-data';

const limit = 5;

const tableProps: TableProps = {
  size: 'small',
  scroll: { x: 'max-content' },
  pagination: {
    defaultPageSize: limit,
    size: 'default',
  },
};

export const DesktopSupplierCrmRestaurantViewScreen = () => {
  const { t } = useTranslation();

  // States for pagination
  const [pageItemsTheyOrderFromYou, setPageItemsTheyOrderFromYou] = useState(1);
  const [pageItemsTheyDoNotOrderFromYou, setPageItemsTheyDoNotOrderFromYou] = useState(1);

  // Fetch data for items they order from you
  const { data: productsDataItemsTheyOrderFromYou, isLoading: isLoadingItemsTheyOrderFromYou } =
    useGetRestaurantDataItemsTheyOrderFromYouApi(
      {},
      { page: pageItemsTheyOrderFromYou.toString(), limit: limit.toString() }
    );

  const itemsTheyOrderFromYouData = useMemo<RestaurantCrmData[]>(
    () => productsDataItemsTheyOrderFromYou?.data || [],
    [productsDataItemsTheyOrderFromYou]
  );

  // Fetch data for items they do not order from you
  const { data: productsDataItemsTheyDoNotOrderFromYou, isLoading: isLoadingItemsTheyDoNotOrderFromYou } =
    useGetRestaurantDataItemsTheyDoNotOrderFromYouApi(
      {},
      { page: pageItemsTheyDoNotOrderFromYou.toString(), limit: limit.toString() }
    );

  const itemsTheyDoNotOrderFromYouData = useMemo<RestaurantCrmData[]>(
    () => productsDataItemsTheyDoNotOrderFromYou?.data || [],
    [productsDataItemsTheyDoNotOrderFromYou]
  );

  // Define common table columns
  const commonColumns: TableColumnsType<RestaurantCrmData> = [
    {
      title: t('image'),
      dataIndex: 'imageUrl',
      render: (val) => (
        <Image
          width={46}
          height={46}
          src={val}
          preview={!!val}
          className='rounded-md'
          fallback='https://placehold.co/600?text=img&font=roboto'
        />
      ),
    },
    {
      title: t('name-en'),
      dataIndex: 'nameEn',
      render: (val) => <TextCell value={val} placeholder={'0'} minWidth={150} />,
    },
    {
      title: t('default-price'),
      dataIndex: 'defaultPrice',
      render: (val) => <TextCell value={val} placeholder={'0'} minWidth={100} />,
    },
    {
      title: t('default-unit'),
      dataIndex: 'defaultUnit',
      render: (val) => <TextCell value={val} placeholder={'0'} minWidth={100} />,
    },
  ];

  // Additional columns for "Items they order from you"
  const itemsTheyOrderFromYouColumns: TableColumnsType<RestaurantCrmData> = [
    ...commonColumns,
    {
      title: t('avg-monthly-qty'),
      dataIndex: 'avgMonthlyQty',
      render: (val) => <TextCell value={val} placeholder={'0'} minWidth={100} />,
    },
    {
      title: t('last-month-sales'),
      dataIndex: 'lastMonthSales',
      render: (val) => <TextCell value={val} placeholder={'0'} minWidth={100} />,
    },
    {
      title: t('ytd-sales'),
      dataIndex: 'lastTwelveMonthSales',
      render: (val) => <TextCell value={val} placeholder={'0'} minWidth={150} />,
    },
  ];

  return (
    <Layout type='sidebar_page_layout' pageTitle='items'>
      <Section name='content'>
        <div className='page'>
          {/* Table for Items they Order From You */}
          <div className='flex flex-col gap-md'>
            <h1 className='font-semibold text-xl'>{t('items-they-order-from-you-title')}</h1>
            <Table
              {...tableProps}
              columns={itemsTheyOrderFromYouColumns}
              dataSource={itemsTheyOrderFromYouData}
              scroll={{ x: 'max-content' }}
              loading={isLoadingItemsTheyOrderFromYou}
              pagination={{
                current: pageItemsTheyOrderFromYou,
                pageSize: limit,
                total: productsDataItemsTheyOrderFromYou?.total || 0,
                onChange: setPageItemsTheyOrderFromYou,
              }}
              locale={{
                emptyText: !isLoadingItemsTheyOrderFromYou ? (
                  <Empty className='py-[150px]' description={t('no_products_description')} />
                ) : (
                  <div className='py-[150px]' />
                ),
              }}
            />
          </div>

          {/* Table for Items they Do Not Order From You */}
          <div className='flex flex-col gap-md'>
            <h1 className='font-semibold text-xl'>{t('items-they-dont-order-from-you-title')}</h1>
            <Table
              {...tableProps}
              columns={commonColumns}
              dataSource={itemsTheyDoNotOrderFromYouData}
              scroll={{ x: 'max-content' }}
              loading={isLoadingItemsTheyDoNotOrderFromYou}
              pagination={{
                current: pageItemsTheyDoNotOrderFromYou,
                pageSize: limit,
                total: productsDataItemsTheyDoNotOrderFromYou?.total || 0,
                onChange: setPageItemsTheyDoNotOrderFromYou,
              }}
              locale={{
                emptyText: !isLoadingItemsTheyDoNotOrderFromYou ? (
                  <Empty className='py-[150px]' description={t('no_products_description')} />
                ) : (
                  <div className='py-[150px]' />
                ),
              }}
            />
          </div>
        </div>
      </Section>
    </Layout>
  );
};

import { useCallback } from 'react';

export const useScrollToSection = <T extends HTMLElement>() => {
  const scrollTo = useCallback((elementRef: React.RefObject<T>) => {
    if (elementRef.current) {
      elementRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, []);

  return { scrollTo };
};

import { message } from 'antd';
import http from 'apis/config/http-service';
import { useQueryWrapper } from 'apis/config/use-api-wrapper';
import type { AxiosError } from 'axios';
import { UseQueryOptions, UseQueryResult } from 'react-query';
import { SupplierRestaurant } from '../../pages/supplier-crm-product/types/supplierRestaurant';

const endpoint = 'ums/v1/crm/customers';
export const getSupplierRestaurantsEndpointIdentifier = endpoint;

type TData = SupplierRestaurant[];
type TError = AxiosError;
type TQueryKey = [string];
type Options = Omit<UseQueryOptions<unknown, TError, TData, TQueryKey>, 'queryKey' | 'queryFn'>;
type ReturnType = UseQueryResult<TData, TError>;

function constructEndpoint() {
  return `${endpoint}`;
}

export function useGetSupplierRestaurantsApi(options?: Options): ReturnType {
  const fullEndpoint = constructEndpoint();

  const queryFn = async () => {
    try {
      const { data } = await http.get(fullEndpoint);

      return data;
    } catch (error: any) {
      if (!error.response.status || !error.response.data.message) throw error;

      if (error.response?.status === 401) throw error;

      message.error(error.response.data.message);

      throw error;
    }
  };

  return useQueryWrapper([fullEndpoint], queryFn, { ...options });
}

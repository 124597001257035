import { Button, Image, Modal, Rate, Table, TableProps, Typography } from 'antd';
import { TextCell } from 'components/text-cell';
import { useDisclosure } from 'hooks/use-disclosure';
import { useEffect, useMemo, useRef, useState } from 'react';
import { HiStar } from 'react-icons/hi';
import { formatPrice } from 'utils/format-price';
import { useScrollToSection } from '../hooks/use-scroll-to-section';
import { SupplierAnalysis } from '../types';
import { PotentialSalesBar } from './potential-sales-bar';
import { ReviewComponent } from './review-component';

const tableProps: TableProps = {
  size: 'small',
  scroll: { x: 'max-content' },
  pagination: {
    defaultPageSize: 5,
    size: 'default',
  },
};

interface SupplierDataProps {
  supplier?: SupplierAnalysis;
}

export const SupplierData = ({ supplier }: SupplierDataProps) => {
  const rightSideElementRef = useRef<HTMLDivElement>(null);
  const [rightSideWidth, setWidth] = useState<number>(0);
  const { scrollTo } = useScrollToSection();

  const restaurantsPortfolioRef = useRef<HTMLDivElement>(null);
  const accountManagersRef = useRef<HTMLDivElement>(null);
  const itemsAnalysisRef = useRef<HTMLDivElement>(null);

  const { isOpen: isOpenReviewsModal, onClose: onCloseReviewsModal, onOpen: onOpenReviewsModal } = useDisclosure();

  // Get right side width
  useEffect(() => {
    if (rightSideElementRef.current) {
      setWidth(rightSideElementRef.current.offsetWidth);
    }

    const handleResize = () => {
      if (rightSideElementRef.current) {
        setWidth(rightSideElementRef.current.offsetWidth);
      }
    };

    window.addEventListener('resize', handleResize);

    // Cleanup listener on unmount
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  console.log(rightSideWidth);

  /**
   * Restaurant Portfolio Table
   */
  interface RestaurantsPortfolio {
    id: number;
    name: string;
    image: string;
    totalSalesVolume: string;
    potentialSalesVolume: string;
  }

  const restaurantsPortfolioColumns: TableProps<RestaurantsPortfolio>['columns'] = [
    {
      dataIndex: 'image',
      fixed: 'left',
      width: 40,
      render: (text) => (
        <Image
          width={35}
          height={35}
          src={text}
          preview={false}
          fallback='https://placehold.co/50?text=img&font=roboto'
        />
      ),
    },
    {
      title: 'Name',
      fixed: 'left',
      render: (_, record) => (
        <Typography.Link href={`/crm/${supplier?.id}/restaurant/${record.id}`}>{record.name}</Typography.Link>
      ),
    },
    {
      title: 'Total Sales',
      dataIndex: 'totalSalesVolume',
      render: (text) => <TextCell value={text} minWidth={100} />,
    },
    {
      title: 'Potential Sales',
      dataIndex: 'potentialSalesVolume',
      render: (text) => <TextCell value={text} minWidth={100} />,
    },
  ];

  const restaurantsPortfolioData = useMemo<RestaurantsPortfolio[]>(() => {
    if (!supplier) return [];

    return supplier?.restaurantsPortfolio.map((i, index) => {
      return {
        key: index,
        ...i,
        totalSalesVolume: formatPrice(i.totalSalesVolume, supplier.currency),
        potentialSalesVolume: formatPrice(i.potentialSalesVolume, supplier.currency),
      };
    });
  }, [supplier]);

  /**
   * Account Managers Table
   */
  interface AccountManager {
    id: number;
    name: string;
    totalSalesVolume: string;
    potentialSalesVolume: string;
    numberOfAccounts: number;
  }

  const accountManagersColumns: TableProps<AccountManager>['columns'] = [
    {
      dataIndex: 'image',
      fixed: 'left',
      width: 40,
      render: (text) => (
        <Image
          width={35}
          height={35}
          src={text}
          preview={false}
          fallback='https://placehold.co/50?text=img&font=roboto'
        />
      ),
    },
    {
      title: 'Name',
      fixed: 'left',
      dataIndex: 'name',
      render: (text) => <TextCell value={text} minWidth={100} />,
    },
    {
      title: 'No. of accounts',
      dataIndex: 'numberOfAccounts',
      render: (text) => <TextCell value={text} minWidth={100} />,
    },
    {
      title: 'Total Sales',
      dataIndex: 'totalSalesVolume',
      render: (text) => <TextCell value={text} minWidth={100} />,
    },
    {
      title: 'Potential Sales',
      dataIndex: 'potentialSalesVolume',
      render: (text) => <TextCell value={text} minWidth={100} />,
    },
  ];

  const accountManagerData = useMemo<AccountManager[]>(() => {
    if (!supplier) return [];

    return supplier?.accountManagers.map((i, index) => {
      return {
        key: index,
        ...i,
        totalSalesVolume: formatPrice(i.totalSalesVolume, supplier.currency),
        potentialSalesVolume: formatPrice(i.potentialSalesVolume, supplier.currency),
      };
    });
  }, [supplier]);

  /**
   * Items Analysis Table
   */
  interface ItemsAnalysis {
    id: number;
    name: string;
    image: string;
    totalSalesVolume: string;
    potentialSalesVolume: string;
  }

  const itemsAnalysisColumns: TableProps<ItemsAnalysis>['columns'] = [
    {
      dataIndex: 'image',
      fixed: 'left',
      width: 40,
      render: (text) => (
        <Image
          width={35}
          height={35}
          src={text}
          preview={false}
          fallback='https://placehold.co/50?text=img&font=roboto'
        />
      ),
    },
    {
      title: 'Name',
      fixed: 'left',
      dataIndex: 'name',
      render: (text) => <TextCell value={text} minWidth={100} />,
    },
    {
      title: 'Total Sales',
      dataIndex: 'totalSalesVolume',
      render: (text) => <TextCell value={text} minWidth={100} />,
    },
    {
      title: 'Potential Sales',
      dataIndex: 'potentialSalesVolume',
      render: (text) => <TextCell value={text} minWidth={100} />,
    },
  ];

  const itemsAnalysisData = useMemo<ItemsAnalysis[]>(() => {
    if (!supplier) return [];

    return supplier?.itemsAnalysis.map((i, index) => {
      return {
        key: index,
        ...i,
        totalSalesVolume: formatPrice(i.totalSalesVolume, supplier.currency),
        potentialSalesVolume: formatPrice(i.potentialSalesVolume, supplier.currency),
      };
    });
  }, [supplier]);

  return (
    <div className='flex gap-md'>
      {/* Left Side */}
      <div className='flex flex-col gap-2xl w-[320px]'>
        <div className='flex gap-md items-center'>
          <Image
            src={supplier?.image}
            preview={false}
            width={70}
            fallback='https://placehold.co/400?text=img&font=roboto'
          />

          <div>
            <Typography.Text
              style={{ minWidth: 200, maxWidth: 200 }}
              ellipsis={{
                suffix: '',
                tooltip: supplier?.name,
              }}
              className='font-semibold text-xl !mb-0'>
              {supplier?.name}
            </Typography.Text>
          </div>
        </div>

        <div className='flex flex-col gap-1'>
          <div className='flex justify-between items-center'>
            <p className='text-lg'>Total Sales</p>
            <p className='text-xl font-semibold'>{formatPrice(supplier?.salesVolume as number, supplier?.currency)}</p>
          </div>

          <div className='flex justify-between items-center'>
            <p className='text-lg'>Potential Sales</p>
            <p className='text-xl font-semibold'>
              {formatPrice(supplier?.potentialSalesVolume as number, supplier?.currency)}
            </p>
          </div>
        </div>

        <PotentialSalesBar
          totalSales={supplier?.salesVolume || 0}
          potentialSales={supplier?.potentialSalesVolume || 0}
        />

        <div className='flex flex-col gap-xs'>
          <h1 className='font-semibold text-lg'>Restaurant&apos;s Ratings</h1>

          <div className='flex items-center gap-2'>
            <Rate allowHalf disabled defaultValue={supplier?.averageRating} />
            <p>{`(${supplier?.averageRating})`}</p>
            <Button className='ms-auto' onClick={onOpenReviewsModal}>
              Reviews
            </Button>

            <Modal
              open={isOpenReviewsModal}
              onCancel={onCloseReviewsModal}
              width={850}
              title={
                <div className='modal-header'>
                  <div className='modal-title'>Restaurants&apos; Reviews</div>
                </div>
              }
              footer={<div className='modal-footer'></div>}>
              <div className='flex flex-col gap-2xl'>
                <div className='flex flex-col justify-center items-center gap-sm mt-md'>
                  <h1 className='font-semibold text-lg'>Overall Ratings</h1>
                  <div className='flex gap-2 items-center'>
                    <Rate
                      allowHalf
                      disabled
                      defaultValue={supplier?.averageRating}
                      character={() => <HiStar size={35} />}
                    />
                    <p className='text-lg'>{`(${supplier?.averageRating})`}</p>
                  </div>
                </div>

                <p className='border-b-1' />

                <div className='grid grid-cols-2 gap-y-[50px] gap-x-4xl'>
                  {supplier?.restaurantsRating.map((r, index) => (
                    <div key={index} className='flex gap-sm'>
                      <Image
                        width={35}
                        height={35}
                        preview={false}
                        fallback='https://placehold.co/50?text=img&font=roboto'
                      />
                      <div className='flex flex-col gap-2'>
                        <p className='font-semibold text-[16px]'>{r.restaurantName}</p>
                        <ReviewComponent ratings={r.ratings} />
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </Modal>
          </div>
        </div>

        <div className='flex flex-col gap-xs'>
          <h1 className='font-semibold text-lg'>Info</h1>
          <div className='flex flex-col gap-0 divide-y-1'>
            <div className='flex items-center justify-between gap-md py-2'>
              <Typography.Link onClick={() => scrollTo(restaurantsPortfolioRef)}>
                Total number of accounts:
              </Typography.Link>
              <p>{supplier?.totalNumberOfAccounts}</p>
            </div>
            <div className='flex items-center justify-between gap-md py-2'>
              <Typography.Link>Total number of potential accounts:</Typography.Link>
              <p>{supplier?.totalNumberOfPotentialAccounts}</p>
            </div>
            <div className='flex items-center justify-between gap-md py-2'>
              <Typography.Link onClick={() => scrollTo(accountManagersRef)}>
                Total number of account managers:
              </Typography.Link>
              <p>{supplier?.totalNumberOfAccountManagers}</p>
            </div>
          </div>
        </div>
      </div>
      {/* Left Side */}

      {/* Divider */}
      <div className='border-[1px] border-default-200' />
      {/* Divider */}

      {/* Right Side */}
      <div
        className='flex flex-col gap-5xl flex-1'
        ref={rightSideElementRef}
        style={{ maxWidth: `calc(100% - ${320 + 34}px)` }}>
        <div className='flex flex-col gap-md' ref={restaurantsPortfolioRef}>
          <h1 className='font-semibold text-xl'>Restaurants Portfolio</h1>
          <Table columns={restaurantsPortfolioColumns} dataSource={restaurantsPortfolioData} {...tableProps} />
        </div>

        <div className='flex flex-col gap-md' ref={accountManagersRef}>
          <h1 className='font-semibold text-xl'>Account Mangers</h1>
          <Table columns={accountManagersColumns} dataSource={accountManagerData} {...tableProps} />
        </div>

        <div className='flex flex-col gap-md' ref={itemsAnalysisRef}>
          <h1 className='font-semibold text-xl'>Items Analysis</h1>
          <Table columns={itemsAnalysisColumns} dataSource={itemsAnalysisData} {...tableProps} />
        </div>
      </div>
      {/* Right Side */}
    </div>
  );
};

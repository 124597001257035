import { AutoComplete, Checkbox, Image, Tag } from 'antd';
import { usePageParams } from 'hooks/use-page-params';
import _debounce from 'lodash/debounce';
import qs from 'query-string';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { searchRestaurants } from '../server/search-engine/search-restaurant-engine';
import { RestaurantSearch, SearchFilterType } from '../types';

interface SearchInputProps {
  defaultValue?: string;
}

export const SearchInput = ({ defaultValue }: SearchInputProps) => {
  const navigate = useNavigate();
  const { supplierId, restaurantId } = useParams<{ supplierId: string; restaurantId: string }>();
  const [options, setOptions] = useState<RestaurantSearch[]>([]);
  const [filterTypes, setFilterTypes] = useState<SearchFilterType[]>([]);
  const { getPageParams, setPageParams } = usePageParams();
  const params = getPageParams();

  // Update search filter state according to query params
  useEffect(() => {
    if (params.searchBy) {
      setFilterTypes(params.searchBy as SearchFilterType[]);
    }

    if (!params.searchBy) {
      setFilterTypes([SearchFilterType.Name]);
    }
  }, [params?.searchBy]);

  const debouncedFetchData = useCallback(
    _debounce(async (searchText: string) => {
      try {
        const result = await searchRestaurants(searchText, Number(supplierId), filterTypes);

        const newOptions: RestaurantSearch[] = result.map((restaurant: RestaurantSearch) => restaurant);

        setOptions(newOptions);
      } catch (error) {
        console.error('Error fetching restaurants:', error);
      }
    }, 500),
    [filterTypes]
  );

  const handleSearch = (searchText: string) => {
    if (searchText) {
      debouncedFetchData(searchText);
    } else {
      setOptions([]);
    }
  };

  const handleSelect = (restaurantId: number) => {
    navigate(
      {
        pathname: `/crm/${supplierId}/restaurant/${restaurantId}`,
        search: qs.stringify({ searchBy: params.searchBy }),
      },
      { replace: true }
    );
  };

  const handleFilterChange = (checkedValues: (string | SearchFilterType)[]) => {
    // Update query params
    setPageParams({ ...params, searchBy: [...checkedValues] });
  };

  const renderOptionLabel = (restaurant: RestaurantSearch) => (
    <div className='flex items-center gap-sm'>
      <Image
        src={restaurant.image}
        preview={false}
        width={60}
        fallback='https://placehold.co/400?text=KASO&font=roboto'
      />

      <div className='flex flex-col gap-[1px]'>
        <div className='flex gap-2 items-center'>
          <h2 className='font-semibold text-md'>{restaurant.name}</h2>
          {restaurant.isNew && (
            <Tag color='#2f54eb' bordered={false} className='text-[10px] leading-[15px] px-[5px]'>
              New
            </Tag>
          )}
        </div>

        <p className='text-default-500 text-[14px]'>Type: {restaurant.cuisineType}</p>
        <div className='flex gap-md text-default-500 text-[14px]'>
          <div>
            <p>Locations: {restaurant.locations.length}</p>
          </div>
          <div className='flex items-center gap-2'>
            <p>Is a customer:</p>
            <Tag
              color={restaurant.isCustomer ? 'green' : 'red'}
              bordered={true}
              className='text-[10px] leading-[15px] px-[5px]'>
              {restaurant.isCustomer ? 'Yes' : 'No'}
            </Tag>
          </div>
        </div>
      </div>
    </div>
  );

  if (restaurantId) {
    return (
      <div className='flex flex-col gap-xs'>
        <div className='flex items-center gap-md'>
          <h1 className='text-lg'>Find a Restaurant by:</h1>
          <Checkbox.Group
            value={filterTypes}
            onChange={(values) => handleFilterChange(values as (string | SearchFilterType)[])}>
            <Checkbox value={SearchFilterType.Name} disabled>
              Name
            </Checkbox>
            <Checkbox value={SearchFilterType.Location}>Location</Checkbox>
            <Checkbox value={SearchFilterType.Product}>Product</Checkbox>
            <Checkbox value={SearchFilterType.Cuisine}>Cuisine</Checkbox>
          </Checkbox.Group>
        </div>
        <AutoComplete
          style={{
            width: '100%',
            height: 48,
          }}
          options={options.map((option) => ({
            key: option.id,
            value: option.name,
            label: renderOptionLabel(option),
          }))}
          defaultValue={defaultValue}
          onSearch={handleSearch}
          onSelect={(_, option) => handleSelect(option.key)}
          placeholder='Write a restaurant name'
          allowClear
        />
      </div>
    );
  }

  return (
    <div className='flex flex-col gap-xs'>
      <div className='flex items-center gap-md select-none'>
        <h1 className='text-xl'>Find a Restaurant by:</h1>
        <Checkbox.Group
          value={filterTypes}
          onChange={(values) => handleFilterChange(values as (string | SearchFilterType)[])}>
          <Checkbox value={SearchFilterType.Name} disabled>
            Name
          </Checkbox>
          <Checkbox value={SearchFilterType.Location}>Location</Checkbox>
          <Checkbox value={SearchFilterType.Product}>Product</Checkbox>
          <Checkbox value={SearchFilterType.Cuisine}>Cuisine</Checkbox>
        </Checkbox.Group>
      </div>

      <AutoComplete
        style={{
          width: 700,
          height: 60,
        }}
        options={options.map((option) => ({
          key: option.id,
          value: option.name,
          label: renderOptionLabel(option),
        }))}
        defaultValue={defaultValue}
        onSearch={handleSearch}
        onSelect={(_, option) => handleSelect(option.key)}
        placeholder='Write a restaurant name'
        allowClear
      />
    </div>
  );
};

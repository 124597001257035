import restaurants from '../../database/restaurants.json';
import { Restaurant } from '../../types';

/**
 * Search for restaurants by cuisine type.
 *
 * @param searchText - The text to search for (cuisine type).
 * @returns An array of restaurants that match the cuisine type.
 */
export const searchRestaurantsByCuisine = (searchText: string): Restaurant[] => {
  // Step 1: Trim and lowercase the search text to ensure case-insensitive matching
  const trimmedCuisineType = searchText.trim().toLowerCase();

  // Step 2: Return the list of  Filter restaurants that have a matching cuisine type
  return restaurants.filter((restaurant) => restaurant.cuisineType.toLowerCase().includes(trimmedCuisineType));
};

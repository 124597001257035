export enum LocalStorageKeys {
  access_token = 'access-token',
  refresh_token = 'refresh-token',
  public_token = 'public-token',
  external_access_token = 'external-access-token',
  is_guest_token = 'is-guest-token',
  language = 'language',
  country = 'country',
  is_support_login = 'is-support-login',
  retry_attempts = 'retry-attempts',
  guest_order_url = 'guest_order_url',
}

export enum SessionStorageKeys {
  otp_expiration_time = 'otp-expiration-time',
}

export const LANGUAGES = {
  en: {
    label: 'English',
    code: 'en',
  },
  ar: {
    label: 'العربية',
    code: 'ar',
  },
};

export enum URL {
  // Marketing
  MARKETING = '/app/marketing',

  // General
  HOME = '/',
  NOT_FOUND = '/404',
  ACCESS_DENIED = '/access-denied',
  APP = '/app',

  // Orders
  ORDERS = '/app/orders',
  ORDERS_CONFIRMED = '/app/orders/confirmed',
  ORDERS_HISTORY = '/app/orders/history',
  ORDERS_DETAILS = '/app/orders/details',

  // Products
  PRODUCTS = '/app/products',

  // Supplier Crm
  SUPPLIER_CRM_RESTAURANT_LIST = '/app/supplier-crm-restaurant-lists',
  SUPPLIER_CRM_RESTAURANT_DETAILS = '/app/supplier-crm-restaurant-view',

  // Profile
  PROFILE = '/app/profile',

  // Pre Login
  GET_STARTED = '/get-started',
  REGISTER = '/register',
  REQUEST_RECEIVED = '/request-received',
  VERIFY_CODE = '/verify-code',
  LOGIN = '/login',
}

export const guestWhitelist = [URL.ORDERS_DETAILS, URL.PROFILE];

// Assuming suppliersData is imported from suppliers.json
import suppliersData from '../database/suppliers.json';

/**
 * Get supplier details from the suppliers.json file based on supplier ID.
 * @param supplierId The ID of the supplier.
 * @returns The supplier details object or null if not found.
 */
export const getSupplierDetails = (supplierId: number) => {
  const supplier = suppliersData.find((s) => s.id === supplierId);
  return supplier || null; // Return the supplier details or null if not found
};

import { FullPageSpinner } from 'components/full-page-spinner';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { SupplierData } from '../components/supplier-data';
import { getSupplierById } from '../server/get-supplier-by-id';
import { SupplierAnalysis } from '../types';

export const SupplierDetailsScreen = () => {
  const { supplierId } = useParams<{ supplierId: string }>();
  const [supplier, setSupplier] = useState<SupplierAnalysis>();
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    if (!supplierId) {
      setLoading(false);
      return;
    }

    try {
      setLoading(true);

      const result = getSupplierById(Number(supplierId));

      if (typeof result !== 'string') {
        setSupplier(result);
      }
    } catch (error) {
      alert(error as string);
      navigate(`/crm/${supplierId}`, { replace: true });
    } finally {
      setTimeout(() => {
        setLoading(false);
      }, 500);
    }
  }, [supplierId]);

  if (loading) return <FullPageSpinner />;
  return (
    <div className='container mx-auto pb-[200px] mt-xl'>
      {/* Data */}
      <div>
        <SupplierData supplier={supplier} />
      </div>
    </div>
  );
};

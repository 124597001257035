import Restaurants from '../../database/restaurants.json'; // Assuming you have a JSON file with restaurant data
import { Restaurant } from '../../types';

/**
 * Search for restaurants by location.
 *
 * @param searchText - The text to search for (location name).
 * @param supplierId - The ID of the supplier (for possible future use).
 * @returns An array of restaurants that match the location.
 */
export const searchRestaurantsByLocation = (searchText: string): Restaurant[] => {
  // Step 1: Trim and lowercase the search text for case-insensitive comparison
  const trimmedLocation = searchText.trim().toLowerCase();

  // Step 2: Return the Filter restaurants by checking if any of their locations match the search text
  return Restaurants.filter((restaurant) =>
    restaurant.locations.some((location) => location.toLowerCase().includes(trimmedLocation))
  );
};

import orders from '../database/orders.json';
import restaurants from '../database/restaurants.json';
import { SimilarRestaurants } from '../types';
import { calculateOverallAverageRating } from './get-restaurant-by-id';

interface FindSimilarRestaurantsReturn {
  highPotentialCustomers: SimilarRestaurants[];
  similarCustomers: SimilarRestaurants[];
}

/**
 * Find similar restaurants based on item similarity.
 * @param restaurantId The ID of the restaurant to find similar restaurants for.
 * @returns An array of restaurants that have the most similar items.
 */
export const findSimilarRestaurants = (restaurantId: number, supplierId: number): FindSimilarRestaurantsReturn => {
  const targetRestaurant = restaurants.find((r) => r.id === restaurantId);

  if (!targetRestaurant) {
    throw new Error('Restaurant not found');
  }

  const getSimilarityScore = (items1: number[], items2: number[]): number => {
    const set1 = new Set(items1);
    const set2 = new Set(items2);
    let commonItems = 0;

    set2.forEach((item) => {
      if (set1.has(item)) {
        commonItems++;
      }
    });

    return commonItems;
  };

  const similarRestaurants = restaurants
    .filter((r) => r.id !== restaurantId) // Exclude the target restaurant
    .map((r) => ({
      restaurant: r,
      similarityScore: getSimilarityScore(targetRestaurant.items, r.items),
    }))
    .sort((a, b) => b.similarityScore - a.similarityScore) // Sort by similarity score
    .map((result) => result.restaurant); // Return only the restaurant objects

  const updatedData = similarRestaurants.map((r) => {
    /*
     * ===
     * Is Customer
     * ===
     */
    const isCustomer = ((): boolean => {
      // Check if there's any order where both supplierId and restaurantId match
      const customerOrderExists = orders.some(
        (order) => order.supplierId === supplierId && order.restaurantId === r.id
      );

      return customerOrderExists;
    })();

    /*
     * ===
     * Average Rating
     * ===
     */
    const averageRating = ((): number => {
      const { suppliersRating } = r;
      return calculateOverallAverageRating(suppliersRating);
    })();

    /*
     * ===
     * Is New
     * ===
     */
    const isNew = ((): boolean => {
      const createdDate = new Date(r.createdAt);
      const currentDate = new Date();

      // Calculate the date 3 months ago
      const threeMonthsAgo = new Date();
      threeMonthsAgo.setMonth(currentDate.getMonth() - 3);

      // Check if the createdDate is within the last 3 months
      return createdDate >= threeMonthsAgo && createdDate <= currentDate;
    })();

    return {
      ...r,
      isNew,
      averageRating,
      isCustomer,
    };
  });

  const highPotentialCustomers = updatedData.filter((r) => !r.isCustomer);
  const similarCustomers = updatedData.filter((r) => r.isCustomer);

  return {
    highPotentialCustomers,
    similarCustomers,
  };
};

import { Avatar } from '@elkaso-app/web-design';
import { List } from 'antd';
import { NavigateFunction } from 'react-router';
import { useNavigate } from 'react-router-dom';
import { URL } from '../../../utils/constants';
import { SupplierRestaurant } from '../types/supplierRestaurant';

interface ImageTileListProps {
  supplierRestaurantsData: SupplierRestaurant[];
}
const handleItemClick = (item: SupplierRestaurant, navigate: NavigateFunction) => {
  console.log('Clicked item:', item);
  navigate(URL.SUPPLIER_CRM_RESTAURANT_DETAILS + `?restaurantId=${item.id}`);
};

const SupplierRestaurantList = ({ supplierRestaurantsData }: ImageTileListProps) => {
  const navigate = useNavigate();
  return (
    <List
      itemLayout='horizontal'
      dataSource={supplierRestaurantsData}
      style={{ width: '100%' }}
      renderItem={(item) => (
        <List.Item
          style={{ width: '100%', paddingLeft: '20px', cursor: 'pointer' }}
          onClick={() => handleItemClick(item, navigate)}>
          <List.Item.Meta
            avatar={<Avatar src={item.imageUrl} />}
            title={`name: ${item.name}`}
            description={`id: ${item.id}`}
          />
        </List.Item>
      )}
    />
  );
};

export default SupplierRestaurantList;

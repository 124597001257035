import items from '../database/items.json';
import orders from '../database/orders.json';
import restaurants from '../database/restaurants.json';
import suppliers from '../database/suppliers.json';
import { AccountManager, RestaurantPortfolio, SupplierAnalysis, SupplierItemAnalysis } from '../types';

/**
 * Function to calculate the overall average rating for a supplier from all restaurant ratings.
 * @param restaurantsRating An array of restaurant ratings for this supplier.
 * @returns The overall average rating across all restaurants, rounded to one decimal place.
 */
const calculateOverallAverageRating = (
  restaurantsRating: { restaurantName: string; ratings: { label: string; ratingValue: number }[] }[]
): number => {
  // Step 1: Calculate the average rating for each restaurant
  const restaurantAverages = restaurantsRating.map((restaurant) => {
    const totalRestaurantRatings = restaurant.ratings.reduce((total, rating) => total + rating.ratingValue, 0);
    const restaurantAverage = totalRestaurantRatings / restaurant.ratings.length;
    return restaurantAverage;
  });

  // Step 2: Calculate the overall average rating by summing up all restaurant averages
  const totalRestaurantAverage = restaurantAverages.reduce((total, avg) => total + avg, 0);

  // Step 3: Return the overall average rating, rounded to 1 decimal place
  return Math.round((totalRestaurantAverage / restaurantsRating.length) * 10) / 10;
};

/**
 * Function to return all account managers for a given supplier.
 * @param supplierId The supplier ID to retrieve SAMs for.
 * @returns An array of AccountManager objects with additional data.
 */
export const getAccountManagersForSupplier = (supplierId: number): AccountManager[] => {
  // Step 1: Find the supplier by supplier ID
  const supplier = suppliers.find((s) => s.id === supplierId);

  if (!supplier) {
    throw new Error(`Supplier with ID "${supplierId}" not found.`);
  }

  // Step 2: Process each SAM (account manager)
  const accountManagers: AccountManager[] = supplier.SAMs.map((sam) => {
    // Calculate the total sales volume for this SAM
    const totalSalesVolume = sam.assignedTo.reduce((totalSales, account) => {
      // Get orders from this supplier after the assignedFrom date for this account
      const accountSales = orders
        .filter(
          (order) =>
            order.supplierId === supplierId &&
            order.restaurantId === account.accountId &&
            new Date(order.createdAt) >= new Date(account.assignedFrom)
        )
        .reduce((sum, order) => sum + order.totalValue, 0);

      return totalSales + accountSales;
    }, 0);

    // Calculate the potential sales volume for this SAM
    const potentialSalesVolume = sam.assignedTo.reduce((potentialSales, account) => {
      // Get all orders (from any supplier) after the assignedFrom date for this account
      const accountPotentialSales = orders
        .filter(
          (order) =>
            order.restaurantId === account.accountId && new Date(order.createdAt) >= new Date(account.assignedFrom)
        )
        .reduce((sum, order) => sum + order.totalValue, 0);

      return potentialSales + accountPotentialSales;
    }, 0);

    // Return the AccountManager object with calculated data
    return {
      id: sam.id,
      name: sam.name,
      image: sam.image,
      numberOfAccounts: sam.assignedTo.length,
      totalSalesVolume,
      potentialSalesVolume,
    };
  });

  return accountManagers;
};

/**
 * Function to get all items for a supplier by supplier ID.
 * @param supplierId The supplier ID to retrieve items for.
 * @returns An array of SupplierItem objects with additional data.
 */
export const getItemsAnalysisForSupplier = (supplierId: number): SupplierItemAnalysis[] => {
  // Step 1: Find the supplier by supplier ID
  const supplier = suppliers.find((s) => s.id === supplierId);

  if (!supplier) {
    throw new Error(`Supplier with ID "${supplierId}" not found.`);
  }

  // Step 2: Process each item for this supplier
  const supplierItems: SupplierItemAnalysis[] = supplier.items.map((itemId) => {
    const itemDetails = items.find((item) => item.id === itemId);

    if (!itemDetails) {
      throw new Error(`Item with ID "${itemId}" not found.`);
    }

    // Step 3: Calculate total sales volume for this item from this supplier
    const totalSalesVolume = orders
      .filter((order) => order.supplierId === supplierId)
      .flatMap((order) => order.items)
      .filter((orderItem) => orderItem.itemId === itemId)
      .reduce((sum, orderItem) => sum + orderItem.quantity * itemDetails.price, 0);

    // Step 4: Calculate potential sales volume (including other suppliers)
    const potentialSalesVolume = orders
      .flatMap((order) => order.items)
      .filter((orderItem) => orderItem.itemId === itemId)
      .reduce((sum, orderItem) => sum + orderItem.quantity * itemDetails.price, 0);

    // Step 5: Construct the SupplierItem object
    return {
      id: itemDetails.id,
      name: itemDetails.name,
      image: itemDetails.image,
      totalSalesVolume,
      potentialSalesVolume,
    };
  });

  return supplierItems;
};

/**
 * Function to get supplier information by supplier ID
 * @param supplierId The ID of the supplier to retrieve.
 * @returns Supplier data object or a message if the supplier is not found.
 */
export const getSupplierById = (supplierId: number): SupplierAnalysis | string => {
  // Step 1: Find the supplier by ID
  const supplier = suppliers.find((s) => s.id === supplierId);

  if (!supplier) {
    throw new Error(`Supplier with ID "${supplierId}" not found.`);
  }

  // Step 2: Get the total number of accounts (restaurants that ordered from this supplier)
  const accounts = orders.filter((order) => order.supplierId === supplierId).map((order) => order.restaurantId);

  const uniqueAccounts = Array.from(new Set(accounts)); // Get unique restaurants that ordered
  const totalNumberOfAccounts = uniqueAccounts.length;

  // Step 3: Get the total number of potential accounts (restaurants that haven't ordered from this supplier)
  const potentialAccounts = restaurants.filter((restaurant) => !uniqueAccounts.includes(restaurant.id));
  const totalNumberOfPotentialAccounts = potentialAccounts.length;

  // Step 4: Get the total number of account managers (SAMs)
  const totalNumberOfAccountManagers = supplier.SAMs.length;

  // Step 5: Calculate the average rating for the supplier
  const averageRating = calculateOverallAverageRating(supplier.restaurantsRating);

  // Step 6: Calculate the total sales from this supplier's orders
  const salesVolume = orders
    .filter((order) => order.supplierId === supplierId)
    .reduce((sum, order) => sum + order.totalValue, 0);

  // Step 7: Calculate potential sales
  const potentialSalesVolume = orders.reduce((sum, order) => sum + order.totalValue, 0);

  // Step 8: Generate the restaurantsPortfolio array
  const restaurantsPortfolio: RestaurantPortfolio[] = uniqueAccounts.map((restaurantId) => {
    const restaurantDetails = restaurants.find((restaurant) => restaurant.id === restaurantId);

    if (!restaurantDetails) {
      throw new Error(`Restaurant with ID "${restaurantId}" not found.`);
    }

    // Calculate total sales volume for this restaurant with this supplier
    const totalSalesVolume = orders
      .filter((order) => order.supplierId === supplierId && order.restaurantId === restaurantId)
      .reduce((sum, order) => sum + order.totalValue, 0);

    // Calculate potential sales volume for this restaurant (orders not from this supplier)
    const potentialSalesVolume = orders
      .filter((order) => order.restaurantId === restaurantId)
      .reduce((sum, order) => sum + order.totalValue, 0);

    return {
      id: restaurantDetails.id,
      name: restaurantDetails.name,
      image: restaurantDetails.image,
      totalSalesVolume,
      potentialSalesVolume,
    };
  });

  // Step 9: return all account managers for a given supplier
  const accountManagers = getAccountManagersForSupplier(supplier.id);

  // Step 10: return all items analysis for a given supplier
  const itemsAnalysis = getItemsAnalysisForSupplier(supplier.id);

  const supplierAnalysis: SupplierAnalysis = {
    ...supplier,
    totalNumberOfAccounts,
    totalNumberOfPotentialAccounts,
    totalNumberOfAccountManagers,
    averageRating,
    salesVolume,
    potentialSalesVolume,
    restaurantsPortfolio,
    accountManagers,
    itemsAnalysis,
  };

  return supplierAnalysis;
};

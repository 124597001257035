import orders from '../../database/orders.json';
import { Restaurant, RestaurantSearch, SearchFilterType } from '../../types';
import { searchRestaurantsByCuisine } from './search-restaurants-by-cuisine';
import { searchRestaurantsByLocation } from './search-restaurants-by-location';
import { searchRestaurantsByName } from './search-restaurants-by-name';
import { searchRestaurantsByProduct } from './search-restaurants-by-product';

export const searchRestaurants = async (
  searchText: string,
  supplierId: number,
  filterTypes: SearchFilterType[]
): Promise<RestaurantSearch[]> => {
  let results: Restaurant[] = [];

  // Check if 'name' filter is selected
  if (filterTypes.includes(SearchFilterType.Name)) {
    const nameResults = searchRestaurantsByName(searchText, supplierId);
    results = [...results, ...nameResults];
  }

  // Check if 'location' filter is selected
  if (filterTypes.includes(SearchFilterType.Location)) {
    const locationResults = searchRestaurantsByLocation(searchText);
    results = [...results, ...locationResults];
  }

  // Check if 'product' filter is selected
  if (filterTypes.includes(SearchFilterType.Product)) {
    const productResults = searchRestaurantsByProduct(searchText);
    results = [...results, ...productResults];
  }

  // Check if 'cuisine' filter is selected
  if (filterTypes.includes(SearchFilterType.Cuisine)) {
    const cuisineResults = searchRestaurantsByCuisine(searchText);
    results = [...results, ...cuisineResults];
  }

  // Optionally remove duplicates if there could be overlap between the results from different filters
  const matchingRestaurants = Array.from(new Set(results.map((r) => r.id))).map(
    (id) => results.find((r) => r.id === id) as RestaurantSearch
  );

  return matchingRestaurants.map((r) => {
    /*
     * ===
     * Is Customer
     * ===
     */
    const isCustomer = ((): boolean => {
      // Check if there's any order where both supplierId and restaurantId match
      return orders.some((order) => order.supplierId === supplierId && order.restaurantId === r.id);
    })();

    /*
     * ===
     * Is New
     * ===
     */
    const isNew = ((): boolean => {
      const createdDate = new Date(r.createdAt);
      const currentDate = new Date();

      // Calculate the date 3 months ago
      const threeMonthsAgo = new Date();
      threeMonthsAgo.setMonth(currentDate.getMonth() - 3);

      // Check if the createdDate is within the last 3 months
      return createdDate >= threeMonthsAgo && createdDate <= currentDate;
    })();

    return {
      ...r,
      isCustomer,
      isNew,
    };
  });
};

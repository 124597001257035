import { useBreakpoint } from 'theme/hooks';
import { DesktopSupplierCrmRestaurantScreen } from './desktop';
import { MobileSupplierCrmRestaurantScreen } from './mobile';

export const SupplierCrmRestaurantScreen = () => {
  const { isMobile } = useBreakpoint();

  if (isMobile) {
    return <MobileSupplierCrmRestaurantScreen />;
  }

  return <DesktopSupplierCrmRestaurantScreen />;
};

import { Layout, Section } from 'layout';
import { useTranslation } from 'react-i18next';
import { useGetSupplierRestaurantsApi } from '../../../../apis/crm/use-get-supplier-restaurants-api';
import { FullPageSpinner } from '../../../../components/full-page-spinner';
import SupplierRestaurantList from '../../components/supplierRestaurantList';

export const DesktopSupplierCrmRestaurantScreen = () => {
  const { t } = useTranslation();
  const { data: supplierRestaurantsData, isLoading: isLoadingSupplierRestaurants } = useGetSupplierRestaurantsApi();

  if (isLoadingSupplierRestaurants) {
    return <FullPageSpinner />;
  }

  return (
    <Layout type='sidebar_page_layout' pageTitle='Supplier CRM'>
      <Section name='content'>
        <div className='page'>
          <div className='page-header'>
            <div className='flex items-start justify-between w-full flex-wrap gap-sm'>
              <div className='page-title-container'>
                <p className='page-title'>{t('supplier_restaurants_title')}</p>
                <p className='page-subtitle'>{t('supplier_restaurants_subtitle')}</p>
              </div>
            </div>
          </div>

          <div className='page-body' id='sp_page_body'>
            <div className='h-full'>
              <SupplierRestaurantList supplierRestaurantsData={supplierRestaurantsData ?? []} />
            </div>
          </div>
        </div>
      </Section>
    </Layout>
  );
};

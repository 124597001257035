import { BrandLogo } from '@elkaso-app/web-design';
import type { MenuProps } from 'antd';
import { Menu } from 'antd';
import { SettingsModal } from 'components/settings-modal';
import { ability } from 'configs/ability';
import { useDisclosure } from 'hooks/use-disclosure';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { HiOutlineCog, HiOutlineShoppingCart, HiOutlineTag, HiOutlineTruck } from 'react-icons/hi';
import { useLocation, useNavigate } from 'react-router-dom';
import { URL } from 'utils/constants';

type MenuItem = Required<MenuProps>['items'][number];

/***********************
 ****** TOP MENU *******
 ***********************/
const TopMenu = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const items = useMemo<MenuItem[]>(() => {
    const i: MenuItem[] = [];

    i.push({
      key: URL.ORDERS,
      label: t('orders'),
      icon: <HiOutlineTruck />,
      style: { paddingInlineStart: 16 },
    });

    i.push({
      key: URL.PRODUCTS,
      label: t('products'),
      icon: <HiOutlineTag />,
      style: { paddingInlineStart: 16 },
    });

    i.push({
      key: URL.MARKETING,
      label: t('marketing'),
      icon: <HiOutlineShoppingCart />,
      style: { paddingInlineStart: 16 },
    });

    if (process.env.REACT_APP_ENV_VAR !== 'production') {
      i.push({
        key: URL.SUPPLIER_CRM_RESTAURANT_LIST,
        label: t('supplier-crm'),
        icon: <HiOutlineShoppingCart />,
        style: { paddingInlineStart: 16 },
      });
    }

    return i;
  }, []);

  const onSelect: MenuProps['onSelect'] = ({ key }) => {
    navigate(key);
  };

  const selectedKey = useMemo<string>(() => {
    return items.find((item) => item?.key === pathname)?.key as string;
  }, [pathname]);

  const defaultKey = URL.ORDERS;

  return (
    <Menu
      onSelect={onSelect}
      className='font-medium'
      selectedKeys={[selectedKey || defaultKey]}
      mode='inline'
      items={items}
    />
  );
};

/**************************
 ****** BOTTOM MENU *******
 **************************/

/** Cleanup Notes 🧹
 * - Set settings modal in App and make it globally accessible
 * - Control open/close the modal from redux
 */
const BottomMenu = () => {
  const { t } = useTranslation();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const items = useMemo<MenuItem[]>(() => {
    const i: MenuItem[] = [];

    if (ability.can('manage', 'Supplier') || process.env.NODE_ENV === 'development') {
      i.push({
        key: 'settings',
        label: t('settings'),
        icon: <HiOutlineCog />,
        style: { paddingInlineStart: 16 },
      });
    }

    return i;
  }, [ability]);

  const onClick: MenuProps['onClick'] = ({ key }) => {
    if (key === 'settings') onOpen();
  };

  return (
    <>
      <Menu onClick={onClick} className='font-medium' mode='inline' items={items} selectable={false} />
      <SettingsModal isOpen={isOpen} onClose={onClose} />
    </>
  );
};

/*****************************
 ****** MENU CONTAINER *******
 *****************************/
export const DesktopMenu = () => {
  return (
    <div className='flex flex-col h-full gap-lg py-lg'>
      <div className='flex items-center justify-center h-[70px]'>
        <BrandLogo />
      </div>

      <div className='flex flex-col justify-between flex-1 p-2xs'>
        <TopMenu />
        <BottomMenu />
      </div>
    </div>
  );
};

import { Rate } from 'antd';
import React from 'react';

interface Rating {
  label: string;
  ratingValue: number;
}

interface ReviewComponentProps {
  ratings: Rating[];
}

export const ReviewComponent: React.FC<ReviewComponentProps> = ({ ratings }) => {
  return (
    <div className='flex flex-col gap-3xs'>
      {ratings.map((rate, index) => (
        <div className='flex gap-sm items-center' key={index}>
          <p className='min-w-[130px]'>{rate.label}:</p>
          <Rate allowHalf disabled defaultValue={rate.ratingValue} />
          <p className='text-[14px]'>{`(${rate?.ratingValue})`}</p>
        </div>
      ))}
    </div>
  );
};

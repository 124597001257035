interface Contact {
  phone: string;
  email: string;
}

interface SupplierRating {
  supplierName: string;
  ratings: { label: string; ratingValue: number }[];
}

interface PriceSensitivity {
  price: number; // Typically between 1 and 10
  quality: number; // Typically between 1 and 10
  paymentTerms: number; // Typically between 1 and 10
}

interface RestaurantSalesChart {
  month: string;
  sales: number;
}

interface RestaurantSAM {
  id?: number;
  name?: string;
  phone?: string;
  email?: string;
}

interface SalesRange {
  lowestOrder: number;
  latestOrder: number;
  highestOrder: number;
  percentage: number;
}

interface ItemsTheyOrderFromYou {
  name: string;
  category: string;
  price: number;
  lastMonthSales: number;
  currentMonthSales: number;
  yearTodaySales: number;
  salesChangePercentage: number;
  frequency: number;
  averageMonthlyQuantity: number;
  unitOfMeasurement: string;
}

interface ItemsYouHaveButOrderFromOthers {
  name: string;
  category: string;
  price: number;
  unitOfMeasurement: string;
  averageMonthlyQuantity: number;
  expectedMonthlySales: {
    minSales: number;
    maxSales: number;
  };
}

interface ItemYouDoNotHave {
  name: string;
  category: string;
  averageMonthlyQuantity: number;
  unitOfMeasurement: string;
  expectedMonthlySales: {
    minSales: number;
    maxSales: number;
  };
}

export interface Restaurant {
  id: number;
  name: string;
  cuisineType: string;
  image: string;
  contacts: {
    manager: Contact;
    salesTeam: Contact;
  };
  suppliersRating: SupplierRating[];
  googleRating: number;
  menuImages: string[];
  locations: string[];
  priceSensitivity: PriceSensitivity;
  items: number[]; // Array of numbers representing item IDs or product codes
  currency: string;
  category: string;
  createdAt: string;
}

export interface RestaurantSearch extends Restaurant {
  isCustomer: boolean;
  isNew: boolean;
}

export interface SimilarRestaurants extends Restaurant {
  averageRating: number;
  isCustomer: boolean;
  isNew: boolean;
}

export interface RestaurantAnalysis extends Restaurant {
  isCustomer: boolean;
  salesVolume: number;
  potentialSalesVolume: number;
  salesRange: SalesRange;
  averageRating: number;
  totalNumberOfRestaurantItems: number;
  totalNumberOfItemsTheyOrderFromYou: number;
  totalNumberOfItemsYouDoNotHave: number;
  totalNumberOfItemsYouHaveButOrderFromOthers: number;
  itemsTheyOrderFromYou: ItemsTheyOrderFromYou[];
  itemsYouHaveButOrderFromOthers: ItemsYouHaveButOrderFromOthers[];
  itemsYouDoNotHave: ItemYouDoNotHave[];
  chartData: RestaurantSalesChart[];
  isNew: boolean;
  sam?: RestaurantSAM;
}

export interface Item {
  id: number;
  name: string;
  price: number;
  unitOfMeasurement: string;
  image: string;
  category: string;
}

export interface OrderItem {
  itemId: number;
  quantity: number;
}

export interface Order {
  id: number;
  restaurantId: number;
  supplierId: number;
  createdAt: string;
  items: OrderItem[];
  totalValue: number;
}

export enum SearchFilterType {
  Name = 'name',
  Location = 'location',
  Product = 'product',
  Cuisine = 'cuisine',
}

interface RestaurantRating {
  restaurantName: string;
  ratings: { label: string; ratingValue: number }[];
}

export interface SupplierSAM {
  name: string;
  phone: string;
  email: string;
  assignedTo: { accountId: number; assignedFrom: string }[];
  image?: string;
}

interface Supplier {
  id: number;
  name: string;
  items: number[];
  image: string;
  currency: string;
  SAMs: SupplierSAM[];
  restaurantsRating: RestaurantRating[];
}

export interface RestaurantPortfolio {
  id: number;
  name: string;
  image: string;
  totalSalesVolume: number;
  potentialSalesVolume: number;
}

export interface AccountManager {
  id: number;
  name: string;
  image: string;
  totalSalesVolume: number;
  potentialSalesVolume: number;
  numberOfAccounts: number;
}

export interface SupplierItemAnalysis {
  id: number;
  name: string;
  image: string;
  totalSalesVolume: number;
  potentialSalesVolume: number;
}

export interface SupplierAnalysis extends Supplier {
  totalNumberOfAccounts: number;
  totalNumberOfPotentialAccounts: number;
  totalNumberOfAccountManagers: number;
  averageRating: number;
  salesVolume: number;
  potentialSalesVolume: number;
  restaurantsPortfolio: RestaurantPortfolio[];
  accountManagers: AccountManager[];
  itemsAnalysis: SupplierItemAnalysis[];
}

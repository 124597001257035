import { NavBar } from 'antd-mobile';
import { Layout, Section } from 'layout';
import { useTranslation } from 'react-i18next';
import { useGetSupplierRestaurantsApi } from '../../../../apis/crm/use-get-supplier-restaurants-api';
import { FullPageSpinner } from '../../../../components/full-page-spinner';
import SupplierRestaurantList from '../../components/supplierRestaurantList';

export const MobileSupplierCrmRestaurantScreen = () => {
  const { t } = useTranslation();
  const { data: supplierRestaurantsData, isLoading: isLoadingSupplierRestaurants } = useGetSupplierRestaurantsApi();

  if (isLoadingSupplierRestaurants) {
    return <FullPageSpinner />;
  }

  const navBarStyle = {
    '--height': '60px',
    '--border-bottom': '1px #cbd5e0 solid',
  };

  return (
    <Layout type='mobile_page_layout'>
      <Section name='header'>
        <NavBar
          back={null}
          left={<p className='text-secondary-500 font-semibold'>{t('supplier_restaurants_title')}</p>}
          style={navBarStyle}
        />
      </Section>

      <Section name='content'>
        <div className='p-md flex flex-col items-center gap-md'>
          <p className='text-secondary-500 font-semibold text-lg'>{t('supplier_restaurants_subtitle')}</p>
          <SupplierRestaurantList supplierRestaurantsData={supplierRestaurantsData ?? []} />
        </div>
      </Section>
    </Layout>
  );
};

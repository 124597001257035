import items from '../../database/items.json';
import restaurants from '../../database/restaurants.json';
import { Restaurant } from '../../types';

export const searchRestaurantsByProduct = (searchText: string): Restaurant[] => {
  // Step 1: Trim and lowercase the search text
  const trimmedName = searchText.trim().toLowerCase();

  // Step 2: Filter items that match the search text
  const matchedItems = items.filter((item) => item.name.toLowerCase().includes(trimmedName));

  // If no items are matched, return an empty array
  if (matchedItems.length === 0) {
    return [];
  }

  // Step 3: Get the item IDs of matched items
  const matchedItemIds = matchedItems.map((item) => item.id);

  // Step 4: Return Filter restaurants that include these items in their inventory
  const matchedRestaurants = restaurants.filter((restaurant) =>
    restaurant.items.some((itemId: number) => matchedItemIds.includes(itemId))
  );

  // Step 5: Return the matched restaurants
  return matchedRestaurants;
};

import restaurants from '../../database/restaurants.json';
import { Restaurant } from '../../types';

/**
 * Fetch all restaurants whose name contains the search text (case insensitive).
 * @param searchText The search text to filter restaurants by.
 * @returns A list of restaurants that match the search text.
 */
export const searchRestaurantsByName = (searchText: string, supplierId: number): Restaurant[] => {
  console.log('searchRestaurantsByName', searchText, supplierId);
  const trimmedName = searchText.trim().toLowerCase();
  return restaurants.filter((restaurant) => restaurant.name.toLowerCase().includes(trimmedName));
};
